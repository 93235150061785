import React from 'react';
import { useEffect, useState } from 'react'
import './style.css'
import LoadingScreen from '../../atomos/loading/loadingScreen/view';
import Tooltip from '../../atomos/tooltip/view';
import { EmptyArray } from '../../domain/models/empty/empty';
import { formatMoney, formatTimeStamp } from '../../utils/formating';
import { lang } from '../../utils/langs';
import { dispatchCustomEvent } from '../../utils/dispatch';
import { ButtonBasic } from '../../atomos/buttons';

const Table = ({ formats=undefined, excludeList = [], headersList = [], emptyMessage, emptyChild, onReload, data, maxHeight, width, children}) => {

    const [headers, SetHeaders] = useState([])

    const format = {
        "money": formatMoney,
        "timestamp": formatTimeStamp,
        "format": formatFormat,
        "tooltip": "",
        "input": ""
    }

    useEffect(() => {
        if (data?.length > 0 && headersList?.length == 0) {
            SetHeaders(Object.keys(data ? data[0] : []))
        } else {
            SetHeaders(headersList ? headersList : [])
        }
    }, [data])

    function formatFormat(param, key) {
        var out = ''
        formats[key]["data"]?.map(item => {
            if (item.id == param) {
                out = lang("es", item[formats[key]["key"]])
            }
        })
        return out
    }

    return (
        <div className='table-container'>
            {
                data ?
                <div>
                    {
                        data == undefined && (
                            <div className='empty-status'>
                                <p>{emptyMessage ? emptyMessage : "Volver a cargar"}</p>
                                <ButtonBasic showLoading={true}  onClick={onReload} >Recargar</ButtonBasic>
                            </div>
                        )
                    }
                    {
                        data.length > 0 && data != EmptyArray && (
                            <table class="table">
                                <thead>
                                    <tr>
                                        {
                                            headers?.length > 0 && (
                                                headers?.map((item, idx) => {
                                                    if (!excludeList.includes(item)) {
                                                        return (
                                                            <th key={idx} scope="col">{item}</th>
                                                        )
                                                    }
                                                })
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody style={{ maxHeight: maxHeight ? maxHeight : '50vh', width: width ? width : "auto"}}>
                                    {
                                        headers.length > 0 && (
                                                data.map((item, idx) => {
                                                    const childrenWithProps = React.Children.map(children, child => {
                                                        const randomHex = Math.floor(Math.random() * 16777215).toString(16);
                                                        return React.cloneElement(child, { data: item, id: randomHex });
                                                    });
                                                    return (
                                                        <tr>
                                                            {
                                                                Object.keys(item).map((key) => {
                                                                    if (!excludeList.includes(key)) {
                                                                        return (
                                                                            <td scope="row">
                                                                                <Tooltip show={formats && formats[key] && formats[key]["type"] === 'tooltip' ? true : false} text={item[key]}>
                                                                                    {
                                                                                        formats && formats[key] && formats[key]["type"] === 'custom'
                                                                                            ? React.cloneElement(formats[key]["children"], {
                                                                                                id: Math.floor(Math.random() * 16777215).toString(16),
                                                                                                data: item
                                                                                            })
                                                                                        : formats && formats[key] && formats[key]["type"] === 'format'
                                                                                            ? format[formats[key]["type"]](item[key], key)
                                                                                        : formats && formats[key] && formats[key]["type"] === 'input'
                                                                                            ? <input className='table-input-format' id={idx} type="text" defaultValue={item[key]} onChange={(e) => {dispatchCustomEvent('table-input-change-'+formats[key]["key"], e)}}></input>
                                                                                        : formats && formats[key] && formats[key]["type"] === 'tooltip'
                                                                                            ? item[key]
                                                                                        : formats && formats[key]
                                                                                            ? format[formats[key]["type"]](item[key])
                                                                                        : item[key]
                                                                                    }
                                                                                </Tooltip>
                                                                            </td>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                children != undefined && (<td scope="row" className='table-child'>{childrenWithProps}</td>)
                                                            }
                                                        </tr>
                                                    )
                                                })
                                        )
                                    }
                                </tbody>
                            </table>
                        )
                    }
                    {
                        data.length > 0 && data == EmptyArray && (
                            <div className='empty-status'>
                                <p>{emptyMessage ? emptyMessage : "Aun no tienes datos"}</p>
                                {emptyChild}
                            </div>
                        )
                    }
                    {
                        data.length == 0 && (
                            <LoadingScreen></LoadingScreen>
                        )
                    }
                </div>
                :
                <div className='empty-status'>
                    <ButtonBasic showLoading={true}  onClick={onReload} >Recargar</ButtonBasic>
                    <p>{emptyMessage ? emptyMessage : "Volver a cargar"}</p>
                </div>
            }
        </div>
    )

}

export default Table
