//Custo css import
import './style.css'
import AccountingReports from '../../organismos/accountingReports/view';
import PlanCuentas from '../../organismos/planCuentas/view';
import Parameterization from '../../organismos/parameterization/view';
import { useUserAuth } from "../../../AuthContext";
import { AiOutlineLogin } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { userSingleton } from "../../classes/User"
import { deleteCookie } from '../../utils/helper';
import { MdOutlineInventory2, MdPostAdd, MdSell } from "react-icons/md";

import Account from '../../organismos/account/view';
import GeneralLayout from '../../templates/general/view';
import ProductsHub from '../products/organismos/productsHub/view';
import { ButtonHub } from '../../atomos/buttons';
import { FaFileInvoice } from 'react-icons/fa';
import Number from '../../insights/atomos/number';
import InsightsGroup from '../../insights/organismos/group';
import { getInsights } from '../../domain/usecase/insights/insights';
import { formatMoney } from '../../utils/formating';

function AppHub(props) {
    const { logOut } = useUserAuth();
    const [profileData, SetProfileData] = useState(null);
    const [insights, setInsights] = useState(null);

    useEffect(() => {
        if (!userSingleton.uid) {
            window.location.replace('/login')
        }
        if (insights === null) {
            getInsights('hub', 'day', setInsights);
        }
        window.addEventListener('insights-tab-hub-click', updateInsights);
        return () => {
            window.removeEventListener('insights-tab-hub-click', updateInsights);
        }
    }, [])

    function updateInsights(event){
        var index = ['day', 'week', 'month'];
        getInsights('hub', index[event.detail.data.tabIndex], setInsights);
    }

    const receiveDataFromChild = (data) => {
        SetProfileData(data);
    };

    function replace(url) {
        window.location.replace(url)
    }

    return (
        <GeneralLayout>
            <div className="app-hub">
                <InsightsGroup id='hub'>
                    <Number
                        data={insights ? insights["sell_count"] : "---"}
                        lable="Ventas totales"
                        icon={<MdPostAdd />}
                    ></Number>
                    <Number
                        data={insights ? formatMoney(insights["sell_value"]) : "$ ---"}
                        lable="Valor facturado"
                        icon={<MdPostAdd />}
                    ></Number>
                    <Number
                        data={insights ? formatMoney(insights["avg_ticket"]) : "$ ---"}
                        lable="Ticket promedio"
                        icon={<MdPostAdd />}
                    ></Number>
                </InsightsGroup>
                <div className='grid-container'>
                    <Account profileData={profileData} SetProfileData={receiveDataFromChild}></Account>
                    <ButtonHub width="95vw" id="pos" onClick={() => { replace("/pos") }} title="Pos" icon={<MdSell />}></ButtonHub>
                    <ButtonHub width="95vw" id="compras" onClick={() => { replace("/compras") }} title="Compras" icon={<MdPostAdd />}></ButtonHub>
                    <ButtonHub width="95vw" id="invoice" onClick={() => { replace("/invoice") }} title="Facturas" icon={<FaFileInvoice />}></ButtonHub>
                    <ButtonHub width="95vw" id="invoice" onClick={() => { replace("/stock") }} title="Inventario" icon={<MdOutlineInventory2 />}></ButtonHub>

                    <AccountingReports></AccountingReports>
                    <Parameterization></Parameterization>
                    <PlanCuentas></PlanCuentas>
                    <ButtonHub width="95vw" id="invoice" onClick={() => [logOut(), deleteCookie("data"), window.location.replace('/login')]} title={<p style={{ color: "rgb(238,110,158)" }}>Salir</p>} icon={<AiOutlineLogin style={{ color: "rgb(238,110,158)" }} />}></ButtonHub>
                </div>
            </div>
        </GeneralLayout>
    )

}

export default AppHub
