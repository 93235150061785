//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BiSave } from 'react-icons/bi';

//React import
import { useState } from 'react';
import Loading from '../../../../atomos/loading/loadingBtn/view';
import { ButtonBasic } from '../../../../atomos/buttons';

//Controller import
import ModalBtn from '../../../../moleculas/modalButton/hub/view';
import { dispatchCustomEvent } from '../../../../utils/dispatch';
import ProductsRepositoryImp from '../../../../infrastructure/products/products-repository-implementation'
import TaxRepositoryImp from '../../../../infrastructure/taxes/tax-repository-implementation'
import { userSingleton } from '../../../../classes/User';
import { ProductFactory } from '../../../../domain/models/products/products';

function NewProduct(props) {

    const productRepository = new ProductsRepositoryImp();
    const taxRepository = new TaxRepositoryImp();

    var [selltaxes, SetSellTaxes] = useState([])
    var [buytaxes, SetBuyTaxes] = useState([])
    var [loading, SetLoading] = useState(false)

    function handleTax() {
        const taxSuscription = taxRepository.get(userSingleton.uid, userSingleton.cid, "sell").subscribe(
            (data) => {
                SetSellTaxes(data)
            },
            (error) => {
                console.log(error)
            }
        )
        const taxSuscription2 = taxRepository.get(userSingleton.uid, userSingleton.cid, "buy").subscribe(
            (data) => {
                SetBuyTaxes(data)
            },
            (error) => {
                console.log(error)
            }
        )
        return () => {
            taxSuscription.unsubscribe()
            taxSuscription2.unsubscribe()
        }
    }

    function clean() {
        SetLoading(false)
        var allInputs = document.querySelectorAll('input');
        allInputs.forEach(singleInput => singleInput.value = '');
        var options = document.getElementById('tax_selector')
        options.selectedIndex = 0
    }

    function create(data) {
        SetLoading(true)
        data.preventDefault()
        var product = ProductFactory.create(
            0,
            data.target[0].value,
            data.target[1].value,
            data.target[2].value,
            data.target[3].value,
            data.target[4].value,
            data.target[5].value,
            data.target[6].value,
        )
        const productCreate = productRepository.create(userSingleton.uid, userSingleton.cid, product).subscribe(
            () => {
                clean()
                dispatchCustomEvent("update-stock",[])
                dispatchCustomEvent("close-newProduct-modal", [])
                SetLoading(false)
            },
            (error) => {
                var err = error.response.data.message
                var messages = {
                    "limit_reached": "Ups, alcanzaste tu limite de prductos aumenta tu plan y remueve todos los limites...",
                    "product_exist": "Existe un producto con el mismo sku.",
                    "sku_empty": "El sku no puede ser vacio.",
                    "name_empty": "El nombre no puede ser vacio.",
                    "value_empty": "El valor de compra no puede ser vacio.",
                    "buy_value_empty": "El valor de venta no puede ser vacio.",
                    "stock_empty": "El stock no puede ser vacio.",
                    "stock_invalid": "La cantidad no es valida.",
                    "buy_value_grater_than": "El valor de compra no puede ser mayor al valor de venta.",
                    "value_invalid": "Valor de venta o compra no valido.",
                }
                dispatchCustomEvent("notification-popup", {message: messages[err], type:"error"})
                SetLoading(false)
            }
        )
        return () => productCreate.unsubscribe()
    }

    return (
        <ModalBtn
                id="newProduct"
                title="Crear"
                onClick={handleTax}
        >
            <div>
                <h2 style={{margin:'10px'}}>Nuevo Poducto</h2>
            </div>
            <div>
                <Form id="compra-form" action="javascript:void(0);" onSubmit={(data) => {create(data)}}>
                    <Form.Group style={{ width: "95%" }}>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="sku" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Sku</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-name" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="buy-buy-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Compra</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-buy-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Cantidad</Form.Label>
                                <Form.Control defaultValue={0} disabled={true} type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-sell-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Venta</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Impuesto Venta</Form.Label>
                                <Form.Select name="color" id="tax_selector">
                                    <option value={2}>Ninguno</option>
                                    {
                                        selltaxes ?
                                            selltaxes.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.description}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Impuesto Compra</Form.Label>
                                <Form.Select name="color" id="tax_buy_selector">
                                    <option value={3}>Ninguno</option>
                                    {
                                        buytaxes ?
                                        buytaxes.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.description}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </Form.Group>
                    <ButtonBasic
                        id="product-create"
                        title="Guardar"
                        width="96%"
                        showLoading={true}
                        style={{ width: "96%", margin:"15px" }}
                        type="submit"
                    ></ButtonBasic>
                </Form>
            </div>
        </ModalBtn>
    )
}

export default NewProduct
