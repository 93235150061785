import { userSingleton } from "../../../classes/User";
import NotesRepositoryImp from '../../../infrastructure/notes/notes-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new NotesRepositoryImp();

function newNote(data) {
    const suscription = repo.create(userSingleton.uid, userSingleton.cid, data).subscribe(
        (response) => {
            dispatchCustomEvent("update-stock", {})
            dispatchCustomEvent("clean-nota", {})
            dispatchCustomEvent("close-nota-modal", {})
            dispatchCustomEvent("notification-popup", {message: "Nota creada exitosamente"})
            /*
            dispatchCustomEvent("reload-list-compras", {})
            */
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error editando", type:"error"})
        }
    )
    return () => suscription.unsubscribe()
}

export { newNote }
