import { NotesRepository } from '../../domain/models/notes/gateway/buys-repository';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class NotesRepositoryImp extends NotesRepository {
  create(uid: string, cid: string | null, data: any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post(`/notes`, data);
  }

  get(uid: string, cid: string | null): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get(`/notes`);
  }

}
