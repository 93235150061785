import ModalBtn from "../../../../moleculas/modalButton/hub/view"
import ProductsTable from "../productsTable/view"
import { ButtonAction, ButtonBasic } from "../../../../atomos/buttons"
import { useEffect, useState } from "react"
import Table from "../../../../moleculas/tablas/view"
import { EmptyArray } from "../../../../domain/models/empty/empty"
import { listNotes } from "../../../../domain/usecase/notes/get"
import { newNote } from "../../../../domain/usecase/notes/create"
import { dispatchCustomEvent } from "../../../../utils/dispatch"

const NewNote = () => {

    const [items, setItems] = useState(EmptyArray)

    useEffect(() => {
        window.addEventListener('clean-nota', clean);
        window.addEventListener('btn-new-note-add', handleEventAdd);
        window.addEventListener('btn-new-note-remove', handleEventRemove);
        window.addEventListener('table-input-change-value', (e) => handleChangeValues(e, 'value'));
        window.addEventListener('table-input-change-amount', (e) => handleChangeValues(e, 'amount'));
        return () => {
            window.removeEventListener('clean-nota', clean);
            window.removeEventListener('btn-new-note-add', handleEventAdd);
            window.removeEventListener('btn-new-note-remove', handleEventRemove);
            window.removeEventListener('table-input-change-value', (e) => handleChangeValues(e, 'value'));
            window.removeEventListener('table-input-change-amount', (e) => handleChangeValues(e, 'amount'));
        }
    }, [])

    function clean() {
        setItems(EmptyArray)
    }

    function handleChangeValues(event, key) {
        var idx = event.detail.data.target.id
        var value = event.detail.data.target.value
        setItems(prevItems => {
            var aux = prevItems
            aux[parseInt(idx)][key] = parseFloat(value)
            return aux
        })
    }

    function handleEventRemove(event) {
        setItems(prevItems => {
            var filtered = prevItems.filter((item) => item.sku != event.detail.data.sku)
            if (filtered.length == 0) {
                return EmptyArray
            }
            return filtered
        })
    }

    function handleEventAdd(event) {
        setItems(prevItems => {
            if (prevItems == EmptyArray) {
                return [mapItem(event.detail.data)]
            }
            if (prevItems.filter((item) => item.sku == event.detail.data.sku).length > 0) {
                return prevItems
            }
            return [...prevItems, mapItem(event.detail.data)]
        })
    }

    function handleSave() {
        if (items == EmptyArray) {
            dispatchCustomEvent("notification-popup", {message: "Agregue productos a la nota", type:"warning"})
            dispatchCustomEvent("loading-btn", {})
            return;
        }
        newNote(items)
        /* listNotes() */
    }

    function mapItem(item) {
        var itemMapped = {
            "sku": item.sku,
            "value": item.buy_value,
            "amount": item.amount,
            "description": ''
        }
        return itemMapped
    }

    return (
        <ModalBtn
            width="70vw"
            id="nota"
            title="Nueva nota"
            on
        >
            <ProductsTable type="clean" tableHeight="200px">
                <ButtonAction modalID="new-note" btnKey="new-note-add">+</ButtonAction>
            </ProductsTable>
            <Table
                data={items}
                maxHeight="150px"
                headersList={["sku", "value", "amount", "description", "actions"]}
                emptyMessage="Adicione produtos"
                formats={{value: { type: 'input', key:'value' }, amount: { type: 'input', key: 'amount' }}}
            >
                <ButtonAction modalID="new-note" btnKey="new-note-remove">x</ButtonAction>
            </Table>

            <ButtonBasic showLoading={true} onClick={handleSave}>Crear nueva nota</ButtonBasic>
        </ModalBtn>
    )
}

export default NewNote
