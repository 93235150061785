import { useEffect, useState } from "react"
import Table from "../../../../moleculas/tablas/view"
import { listNotes } from "../../../../domain/usecase/notes/get"

const ListNotes = () => {

    const [items, setItems] = useState([])

    useEffect(() => {
        if(items === undefined || items.length === 0){
            listNotes(setItems)
        }
        window.addEventListener('update-notes', handleUpdate);
        return () => {
            window.removeEventListener('update-notes', handleUpdate);
        };
    }, []);

    function handleUpdate(event) {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();
        listNotes(setItems)
    }

    return (
        <Table
            data={items}
            maxHeight="64vh"
            emptyMessage="Aun no tiene notas"
            headersList={["Nota", "Sku", "Valor unitario", "Cantidad", "Descripcion"]}
            onReload={() => listNotes(setItems)}
        />
    )
}

export default ListNotes
