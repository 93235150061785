//Custo css import
import './style.css'

//Widgets import
import NewProduct from '../newProduct/view';
import ProductsTable from '../productsTable/view';
import { dispatchCustomEvent } from '../../../../utils/dispatch'
import { MdOutlineInventory2 } from 'react-icons/md';
import LoadProductsFromFile from '../loadProductsFromFile/view';
import ModalBtn from '../../../../moleculas/modalButton/hub/view';
import NewNote from '../../organismos/newNote/view';
import GeneralLayout from '../../../../templates/general/view';
import ButtonGroup from '../../../../moleculas/buttonGroup/view';
import Tab from '../../../../moleculas/tab/view';
import { useEffect, useState } from 'react';
import Table from '../../../../moleculas/tablas/view';
import ListNotes from '../listNotes/view';


function ProductsHub(props) {

    const [tab, setTab] = useState("stock")
    const tabs = [
        { title: "Productos", eventKey: "stock"},
        { title: "Notas", eventKey: "notas" }
    ]

    useEffect(() => {
        window.addEventListener('TabLink-stock', handleTab);
        return () => {
            window.removeEventListener('TabLink-stock', handleTab);
        };
    }, [])

    function handleTab(e) {
        setTab(e.detail.eventKey)
    }

    return (
        <GeneralLayout>
            <div className='pos'>
                <ButtonGroup>
                    {
                        tab === "stock" && (
                            <div style={{display: 'flex'}}>
                                <NewProduct></NewProduct>
                                <LoadProductsFromFile></LoadProductsFromFile>
                            </div>
                        )
                    }
                    {
                        tab === "notas" && (
                            <NewNote></NewNote>
                        )
                    }
                </ButtonGroup>
                <br></br>
                <Tab tabKey="stock" tabs={tabs} />
                {
                    tab === "stock" && (
                        <ProductsTable
                            tableHeight="50vh"
                            forceLoadData={true}
                            compras={true}
                            buy={true}
                            hiddenAction={true}
                            enableDelete={true}
                            enableEdit={true}
                        ></ProductsTable>
                    )
                }
                {
                    tab === "notas" && (
                        <ListNotes></ListNotes>
                    )
                }
            </div>
        </GeneralLayout>
    )
}

export default ProductsHub
