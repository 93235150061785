import { userSingleton } from "../../../classes/User";
import NotesRepositoryImp from '../../../infrastructure/notes/notes-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";
import { EmptyArray } from "../../models/empty/empty";

const repo = new NotesRepositoryImp();

function listNotes(func) {
    const suscription = repo.get(userSingleton.uid, userSingleton.cid).subscribe(
        (response) => {
            if (response.data.length === 0) {
                func(EmptyArray)
            } else {
                func(response.data)
            }
            dispatchCustomEvent('loading-btn')
        },
        (error) => {
            func(undefined)
            dispatchCustomEvent('loading-btn')
        }

    )
    return () => suscription.unsubscribe()
}

export { listNotes }
